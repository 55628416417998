<template>
  <div class="teambigbox">
    <div class="img_header"><img :src="encnimg[num].imgurl" alt="" /></div>
    <div class="fs30">{{textencn[num].title}}</div>
    <div class="fs17">
      {{textencn[num].text1}}
    </div>
    <div class="fs17">
      {{textencn[num].text2}}
    </div>
    <div class="fs17">
      {{textencn[num].text3}}
    </div>
    <div class="headline">{{textencn[num].title1}}</div>
    <div class="imgBox">
      <div class="ma010">
        <img @click="link1()" src="../../img/photo1.png" alt="" />
        <p>{{imgtitle[num].imgtit1}}</p>
      </div>
      <div class="allocationA">
        <div>
          <img @click="link2()" src="../../img/photo2.png" alt="" />
          <p>{{imgtitle[num].imgtit2}}</p>
        </div>
        <div>
          <img @click="link3()" src="../../img/photo3.png" alt="" />
          <p>{{imgtitle[num].imgtit3}}</p>
        </div>
      </div>
      <div class="allocationB">
        <div>
          <img @click="link4()" src="../../img/photo4.png" alt="" />
          <p>{{imgtitle[num].imgtit4}}</p>
        </div>
        <div>
          <img @click="link5()" src="../../img/photo5.png" alt="" />
          <p>{{imgtitle[num].imgtit5}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["num"]),
  },

  data() {
    return {
      imgtitle:[
        { 
          imgtit1:'湿地旁的禅修馆',
          imgtit2:'上海博物馆东馆',
          imgtit3:'安托山空中花园',
          imgtit4:'长亭',
          imgtit5:'高屋',
        },
        {
          imgtit1:'Meditation Hall',
          imgtit2:'Shanghai Museum East Hall',
          imgtit3:'Lifted Garden',
          imgtit4:'Long Pavilion',
          imgtit5:'Big Hut',
        }
      ],

      textencn:[
        { 
          title:'建筑设计团队',
          title1:'- 团队设计案例 -',
          text1:'丘建筑设计事务所，由主持建筑师于岛创立。创始人于苏黎世瑞士联邦理工大学（ETH）获得本科与硕士学位，瑞士建筑与工程协会SIA注册建筑师，曾就职于苏黎世联邦理工大学建筑系教席，并在瑞士与日本积累了多年建筑实践经验。目前事务所位于北京。',
          text2:'丘建筑坚持通过项目本身的品质，呈现事务所的价值。创始人曾入选2019年FA中国青年建筑师奖TOP20，其主持并发表的项目，获国内外众多奖项：雄安绿色建筑技术研发展示中心竞赛一等奖（中标），上海博物馆东馆全球设计竞赛青年组优胜奖，深圳蛇口太子湾综合设施国际竞赛竞赛优胜奖等。',
          text3:'其中，在归国后首个主持设计的建成项目，“湿地旁的禅修馆”，赢得了gooood、Archdaily、Wallpaper* 卷宗、Dezeen、现代装饰、日本Elle Décor、德国AIT、gestalten、意大利Marie Claire Maison、VOGUE Beauty、美国EDITION29等国际媒体的关注和报道，并获得第十二届台湾TID室内设计大奖，DFA亚洲最具影响力设计铜奖等，第十七届国际设计传媒奖年度休闲大奖，第十一届园冶杯国际建筑奖金奖（亚洲唯一获金奖事务所），等诸多国内外重要奖项。'
        },
        {
          title:'Hil Architects',
          title1:'',
          text1:'Hil Architects is founded by principal architect Yu Dao. Mr. Yu graduated with BA and MA degrees from the Swiss Federal Institute of Technology in Zürich (ETH) and is a member of the Swiss Society of Engineers and Architects (SIA). Mr. Yu has worked in the Department of Architecture of ETH and has gained many years of architectural experience in Switzerland and Japan. YUARCHITECTS is currently based in Beijing.',
          text2:'Hil Architects is committed to substantializing its values by delivering high-quality projects. Mr. Yu was shortlisted in 2019 FA Emerging Architect Award (TOP 20) and has headed a host of award-winning projects, including First Prize (Bid Winner) in the Xiongan Research and Exhibition Center for Green Architectural Technology Design Competition, Merit Award in 2016 International Design Competition of Shanghai Museum East, and Merit Award in 2014 Shekou Prince Bay Kiosk Design Competition. ',
          text3:'Meditation Space Beside The Wetland is the first completed project that Mr. Yu headed after he returned to China. It has been featured widely by press around the world including Gooood, Archdaily, Wallpaper* China edition, Dezeen.com, Modern Decoration, Elle Décor Japan, AIT, Gestalten, Marie Claire Maison Italia, VOGUE Beauty, and EDITION 29 and many more, and has also been accoladed with the 12th Taiwan Interior Design Award, Bronze Award in Design for Asia, the 17th IDMA-Annual Leisure Space Award, Gold Award of the 11th Yuan Ye Awards for International Architectural Projects (the only gold award winner in Asia) and other major awards around the globe.',
        }
      ],

      encnimg: [
        {
          imgurl: require("../../img/headerimg.png"),
        },
        {
          imgurl: require("../../img/headerenimg.png"),
        },
      ],
    };
  },
  methods:{
    link1(){
      window.location = 'https://hilarchitects.com/hil003-meditation-hall/'
    },
    link2(){
      window.location = 'https://hilarchitects.com/hilx2016-shanghai-museum-east/'
    },
    link3(){
      window.location = 'https://hilarchitects.com/hil004-lifted-garden/'
    },
    link4(){
      window.location = 'https://hilarchitects.com/hilx2015-long-pavilion/'
    },
    link5(){
      window.location = 'https://hilarchitects.com/hilx2015-big-hut/'
    }
  }
};
</script>

<style lang="less" scoped>
.allocationA {
  display: flex;
}
.ma010 {
  height: 3.45rem;
  margin-bottom: 0.1rem;
}
.ma010 img {
  width: 100%;
  height: 100%;
}
.imgBox p {
  text-align: center;
  color: white;
  font-size: 0.16rem;
  position: relative;
  bottom: 0.2rem;
}
.allocationB {
  display: flex;
}

.allocationB :nth-child(2) img {
  width: 100%;
}
.allocationB :nth-child(1) {
  width: 100%;
  flex: 1;
  height: 2.53rem;
  margin-right: 0.1rem;
}

.allocationA :nth-child(1) {
  width: 3.25rem;
  height: 2.53rem;
  margin-right: 0.1rem;
}
.allocationA :nth-child(2) {
  height: 2.53rem;
  flex: 1;
}
.allocationA :nth-child(2) img {
  width: 100%;
}
.imgBox {
  width: 8rem;
  margin: 0 auto;
}
.allocationA,
.allocationB {
  margin-bottom: 0.1rem;
}

.headline {
  font-size: 0.26rem;
  text-align: center;
  font-weight: 700;
  margin: 0.4rem 0;
}
.teambigbox {
  width: 8rem;
  height: 100%;
  margin: 0 auto;
}
.img_header {
  height: 0.8rem;
  width: 4.26rem;
  margin: 0 auto;
  img {
    width: 100%;
  }
}
.fs30 {
  margin-top: 0.8rem;
  font-size: 0.3rem;
  text-align: center;
}
.fs17 {
  margin-top: 0.3rem;
  font-size: 0.17rem;
  font-weight: 300;
  line-height: 0.4rem;
  letter-spacing: 0.02rem;
}
.teambox {
  width: 100%;
  display: flex;
  overflow: hidden;
  flex-wrap: wrap;
  margin: 0 auto;
  justify-content: center;

  display: flex;
  flex: 1;
}
.vfor:last-child {
  margin: 0.6rem auto;
}
.bcopy {
  font-weight: 800;
  color: #a08e75;
}
.vfor {
  width: 31%;
  text-align: center;
  font-size: 0.16rem;
  margin-top: 0.6rem;
}
.vfor div {
  margin-bottom: 0.15rem;
}
.vfor img {
  height: 0.27rem;
  text-align: center;
  margin: 0 auto;
}
</style>